html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.option {
  padding-right: 5% !important;
  margin-right: 5% !important;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.psh {
  position: absolute;
  left: 42%;
  color: brown;
  top: 50%;
}

.wd-100 {
  width: 100%;
  float: left;
  
}

.wd-50 {
  width: 50%;
  float: left;
}

.fl-r{
  float: right !important;
}

.wd-3 {
  width: 12%;
  float: right;
}

.svgR {
  margin-top: 3%;
  margin-right: 20%;
}

.fl-r {
  float: right;
}

.mg {
  margin-right: 5%;
  margin-top: 0.8%;
}

.mrr-15{
  margin-right: 15%;
}

.mg-b-3{
  margin-bottom: 3%;
}

.dsply-contents{
  display: contents;
}

.mg-l-5{
  margin-left: 5%;
}

.mg-r {
  margin-right: 1%;
}

.rl {
  margin-top: 1%;
  margin-bottom: 1%;
}

.crp {
  cursor: pointer;
}

.rls {
  width: 20%;
  float: right;
}

.txtU {
  text-transform: uppercase !important;
}

/* --------------------------HEADER-------------------------------------------- */

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 0.3rem;
  outline: 0;
}

.headerShadow {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 3px 3px 10px #ccc;
}

.imgs {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.pd {
  padding: 1%;
}

.svgicon {
  text-align: center;
  width: 25px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 20%;
  /* margin-top: -17px; */
  /* font-size: 1.5rem; */
  opacity: 0.3;
  transition: color 300ms;
}

/* -----------------------TEXT-EDITOR------------------------------------------------------- */

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.txt {
  /* padding: 5%;
  width: 100%;
  float: left; */
  background-color: #e9eceee0;
  /* background: rgba(167, 167, 167, 0.1); */
  min-height: 100vh;
  /* padding-left: 25%;
  padding-top: 5%;
  padding-right: 2%;
  padding-bottom: 5%; */
  padding: 2.3% 3% 5% 23.3%;
}

@media only screen and (max-width:800px){
  .txt {
   
    padding: 2.3% 3% 5% 3%;
  }
}

.txtb {
  background: #fff;
  border-radius: 8px;
  /* min-height: 400px; */
  box-shadow: -1px 4px 10px #ccc;
}

.textEditor {
  min-height: 400px;
  border: 1px solid #dfdfdf;
  margin-bottom: 3%;
  border-radius: 5px;
}

.mrt-2 {
  margin-top: 1%;
}

.preImage {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}
.addimage{
  margin-top: 25%;
  width: 78%;
  height: 55%;
  border-radius: 5px;
  cursor: pointer;
}

.tableimage {
  padding: 0% !important;
  width: 35%;
  height: 50%;
  border-radius: 5px;
  cursor: pointer;
}

.rdw-editor-main {
  min-height: 400px;
}

.imghover:hover .imgh {
  display: block;
  position: fixed;
  top: 5%;
  width: 37%;
  float: left;
  left: 30%;
  z-index: 99;
  bottom: 2%;
  height: 80vh;
}

.form-control {
  cursor: pointer;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control[type=file] {
  overflow: hidden;
}

input[type="file" i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: start !important;
  border: initial;
  overflow: hidden !important;
  border: 1px solid #ced4da;
  padding: 1%;
  border-radius: 0.25rem;
}




.imghover:hover .imgh .imghImage {
  max-height: 95vh;
  background-color: #fff;
}

.imghImage {
 
  height: 100%;
  float: left;
  min-height: calc(100vh - 212px);
  padding: 1%;
  border-radius: 10px;
  box-shadow: -1px 4px 10px #121212;
}

.imgh {
  display: none;
  /* background-color: #fff; */
  z-index: 999999;
  position: relative;
  border-radius: 10px;
  width: 100%;
  min-height: calc(100vh - 212px);
}

.edt {
  cursor: pointer;
  /* width: 100%; */
  /* float: left; */
}

.edt:hover {
  border-bottom: 1px solid rgb(255, 51, 0);
}

.edt a:hover {
  text-decoration: none;
  color: #fd7e14;
}

.ldbi {
  position: absolute;
  left: 55%;
  z-index: 9999999999999999999999999;
  top: 50%;
}

.edt:hover span {
  /* position: absolute;
  width: 10%;
  margin-top: -2%;
  margin-left: -1%;
  font-size: 20px; */
  color: #fd7e14;
  /* background-color: red; */
}

.edt:hover p {
  /* border-bottom: 1px solid rgb(255, 51, 0); */
  /* position: absolute; */
  /* width: 10%; */
  /* margin-top: -2%;
  margin-left: 0%; */
  /* font-size: 20px; */
  color: #fd7e14;
  /* background-color: red; */
}

/* -------------LOGIN---------------------------- */


/* .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  padding:3%
} */

.loginloader {
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  margin-top: 10%;
}

.type_error {
  color: rgb(240, 10, 10);
  margin-top: 5%;
}

.bckLog {
  background-image: linear-gradient(
    135deg,
    #667eea 0%,
    #764ba2 100%
  ) !important;
  width: 100%;
  float: left;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  justify-content: center;
}

.nmc {
  background-color: #fd7e14;
  color: white;
  line-height: 40px;
}

.form-signin {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 1%;
  border-radius: 5px;
  background: #ffffff;
  /* box-shadow: -4px 3px 4px -3px #777; */
  box-shadow: 0 0.76875rem 2.4875rem rgba(52, 58, 64, 0.3),
    0 1.3375rem 1.70625rem rgba(52, 58, 64, 0.3),
    0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.225rem 0.4375rem rgba(52, 58, 64, 0.3);
  /* min-height: calc(100vh - 212px); */
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  /* position: relative; */
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #6c757d;
}

.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*----------------------event---------------------------------------------------  */
.pdl-0 {
  padding-left: 0px !important;
  width: 20% !important;
}

/* ------------------LOADING----------------------------------- */
.loading_c {
  justify-content: center;
  display: flex;
}

/* --------------------------------FEEDBACK-RESPONSE-PRINT-------------------------------------------------------- */


.rt{
  padding-left: 0.6%;
  width: 24%;
}

.flr {
  display: flex;
  margin: 2% 0%;
}

.resp {
  width: 100%;
  float: left;
  margin-bottom: 2%;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 3%;
}

.flx {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.flx h4 {
  padding: 4px 10px 0px 0px;
}

.prgbr {
  width: 70%;
  float: left;
  padding: 1% 0% 0% 5%;
}

.pdanswer {
  padding: 2% 0% 1% 3%;
}

.err {
  text-align: center;
  color: rgb(233, 13, 13);
  padding: 10%;
}

/* -------------------time-selection------------------------------------- */

.rc-time-picker-input {
  border: none !important;

  padding: 0 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057;
  height: 20px !important;
}

.rc-time-picker-clear {
  display: none !important;
}

/* -----------ADD-MORE-EDIT-FEEDBACK----------------------- */

.pd-0 {
  padding-left: 0px !important;
}

.removeMore {
  width: 5% !important;
  /* justify-content: center; */
  /* align-items: center; */
  /* align-content: center; */
  /* text-align: center; */
  display: contents;
  cursor: pointer;
}

.addmore {
  padding-top: 1%;
  border-top: 1px solid rgba(26, 54, 126, 0.125);
  font-size: 20px;
  margin-bottom: 2%;
}

.cursor {
  cursor: pointer;
}

.addotherurl {
  width: 65%;
}

.addotherurl svg {
  width: 28px;
  margin-left: 5px;
  color: #3f6ad8;
}

.add-more-feild {
  width: 100%;
  /* float: left; */
  display: flex;
  margin: 0 auto;
  outline: none;
}

.add-more-feild svg {
  width: 50px;
  margin: 0 auto;
}

/* ------------ERROR-PAGE--------------------------------- */

.error {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  min-height: 100vh;
}

.errorh {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: rgb(255, 255, 255);
}

/* ----------------------HOME------------------------------------------- */

.mrabt{
  text-decoration: none !important;
}

.mrabt:hover {
  font-weight: bold;
}

.widget-chart {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.widget-chart .widget-chart-content {
  position: relative;
  z-index: 5;
}

.icon-wrapper {
  width: 54px;
  height: 54px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center;
}

.rounded {
  border-radius: 0.25rem !important;
}

.icon-wrapper .icon-wrapper-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0.2;
}

.bg-warning {
  background-color: #f7b924 !important;
}

.icon-wrapper i {
  margin: 0 auto;
  font-size: 1.7rem;
  position: relative;
  z-index: 5;
}

.widget-chart .widget-numbers {
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}

.text-warning {
  color: #f7b924 !important;
}

.opacity-10 {
  opacity: 1 !important;
}
.fsize-1 {
  font-size: 0.95rem !important;
}
.widget-chart .widget-subheading {
  margin: -0.5rem 0 0;
  display: block;
  opacity: 0.6;
}

.widget-chart .widget-numbers + .widget-chart-flex,
.widget-chart .widget-numbers + .widget-description,
.widget-chart .widget-numbers + .widget-subheading {
  margin-top: -0.5rem;
}


.widget-chart .widget-description {
  margin: 1rem 0 0;
}

.opacity-8 {
  opacity: .8!important;
}

.text-danger {
  color: #d92550!important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem!important;
}


/* -------------------CONTENT----------------------------------- */

.ps-a {
  position: absolute;
  right: 0;
}